import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { NavigateTo } from '../actions/navigation.action';
import { PermissionQueries } from '../states/permissions.queries';

export const featurePermissionsGuard: ResolveFn<boolean> = (route, _state) => {
  const store = inject(Store);

  const hasFeaturePermission = store.selectSnapshot(
    PermissionQueries.hasPermission(route.data.featureKey)
  );

  if (hasFeaturePermission) {
    return true;
  }

  const navigationPath = route.data.redirectTo || 'dashboard';

  store.dispatch(new NavigateTo([navigationPath]));

  return false;
};
